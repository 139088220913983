.Button {
  border: none;
  border-radius: 0.25rem;
  color: var(--foreground-button-inactive);
  font-size: 1rem;
  padding: .75rem 1.5rem;
  width: fit-content;
}
  .Button:enabled {
    background-color: var(--background-button-inactive);
  }
  .Button:enabled:hover {
    background-color: var(--background-button-active);
    color: var(--foreground-button-active);
    cursor: pointer;
  }
  .Button:disabled {
    background-color: var(--background-button-disabled);
  }

.ButtonIcon {
  background: inherit;
  border: 0;
}
  .ButtonIcon:hover {
    cursor: pointer;
  }

.Dropdown {
  background-color: inherit;
  border: solid 1px var(--border-light);
  border-radius: 0.25rem;
  padding: .5rem;
  width: fit-content;
  > span {
    padding: 0 .5rem;
  }
  > input {
    background-color: inherit;
    color: inherit;
    font-size: 1rem;
    outline: none;
    width: 15rem;
  }

  > input:focus {
    color: inherit;
  }
  > input:not(focus) {
    color: var(--foreground-light);
  }

  > button {
    background-color: inherit;
    border: none;
    cursor: pointer;
    margin: 0 .25rem 0 .5rem;
  }
}
  .Dropdown:hover, .Dropdown:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    > input {
      color: inherit;
    }
  }
  .DropdownButton {
    border: none;
    cursor: pointer;
  }
  .DropdownInput {
    border: none;
    border-bottom: dotted 2px var(--border-input-inactive);
    color: var(--background-dark);
  }
  .DropdownInput:hover, .DropdownInput:focus-within {
    border-bottom: dotted 2px var(--border-input-active);
  }
  .DropdownMenu {
    background-color: var(--background-light);
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: var(--foreground-light);
    margin-top: .25rem;
    padding: .5rem 0;
    position: absolute;
    width: 20rem;
    z-index: 10;
    > div {
      cursor: pointer;
      padding: .5rem;
      > svg {
        border-right: solid 1px var(--border-light);
        margin-right: .5rem;
        width: 2rem;
      }
    }
    > div:hover {
      background-color: var(--background-medium);
      color: var(--foreground-medium);
    }
  }

.ImageSummary {
  border: solid 1px var(--border-light);
  border-radius: 0.25rem;
  height: 100px;
  max-height: 100px;
  max-width: 150px;
  min-height: 100px;
  min-width: 150px;
  width: 150px;
}

.Input {
  border: solid 1px var(--border-light);
  border-radius: 0.25rem;
  padding: .5rem;
  width: fit-content;
  > span {
    padding: 0 .5rem;
  }
  > input {
    background-color: inherit;
    border: none;
    border-bottom: dotted 2px var(--border-input-inactive);
    font-size: 1rem;
    outline: none;
  }
  > input:focus {
    color: inherit;
  }
  > input:not(focus) {
    color: var(--foreground-light);
  }
}
  .Input:hover, .Input:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    > input {
      color: var(--foreground-dark);
    }
  }
  .InputError {
    > input {
      border-bottom: dotted 2px var(--border-input-error);
    }
  }
  .InputOk:hover {
    > input {
      border-bottom: dotted 2px var(--border-input-active);
    }
  }

.OTP {
  column-gap: .5rem;
  display: flex;
  > input {
    border: solid 1px var(--border-medium);
    border-radius: 0.25rem;
    cursor: pointer;
    outline: none;
    padding: .5rem 1rem;
    text-align: center;
    width: 1rem;
  }
  > input:focus {
    border: solid 1px var(--border-dark);
    color: inherit;
  }
  > input:not(focus) {
    color: var(--foreground-light);
  }
}

.Rating {
  column-gap: .25rem;
  display: flex;
  .Enabled {
    cursor: pointer;
    :hover {
      transform: scale(1.1);
    }
  }
}

.RatingStar {
  color: orange;
}

.Search {
  background-color: var(--background-medium);
  border: solid 1px var(--border-light);
  border-radius: 0.25rem;
  color: var(--foreground-light);
  font-size: 1rem;
  overflow: hidden;
  width: fit-content;
  button {
    border:  none;
    font-size: inherit;
  }
  button:enabled {
    background-color: var(--background-button-inactive);
    color: var(--foreground-button-inactive);
    cursor: pointer;
  }
  button:first-child {
    padding: .75rem 1rem;
  }
  button:last-child {
    padding: .75rem 1.5rem;
  }
  input {
    background-color: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    outline: none;
    padding: 0 1rem;
  }
}
  .Search:hover, .Search:focus-within {
    background-color: inherit;
    color: var(--foreground-medium);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    button:enabled {
      background-color: var(--background-button-active);
      color: var(--foreground-button-active);
    }
  }
  .Search:disabled {
    button {
      background-color: var(--background-button-disabled);
    }
  }
  @media only screen and (max-width: 1024px) {
    .Search-md {
      input {
        width: 10rem;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    .Search-lg {
      input {
        width: 20rem;
      }
    }
  }

.Text {
}
  .TextCaption {
    color: var(--foreground-light);
  }
  .TextNormal {
    font-size: 1rem;
  }
  .TextSmall {
    font-size: .75rem;
  }
  .TextSubtitle {
    font-size: 1.25rem;
  }
  .TextTitle {
    font-size: 1.5rem;
  }

.Textarea {
  > textarea {
    background-color: inherit;
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    font-size: 1rem;
    outline: none;
    padding: .5rem;
  }
  :focus-within, :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: inherit;
  }
  :not(focus-within) {
    color: var(--foreground-light);
  }
}

.Upload {
  display: flex;
  flex-direction: column;
  > input {
    border: solid 1px var(--border-light);
    font-size: 1rem;
    width: 14rem;
  }
  > input::file-selector-button {
    background-color: var(--background-button-inactive);
    border: none;
    border-radius: 0.25rem;
    color: var(--foreground-button-inactive);
    font-size: 1rem;
    padding: .5rem .7rem;
  }
  > input::file-selector-button:hover {
    background-color: var(--background-button-active);
    color: var(--foreground-button-active);
    cursor: pointer;
  }
}

.Editor {
  border: solid 1px var(--border-light);
  border-radius: 0.25rem;
  > div {
    align: justify;
    outline: none;
    overflow-y: auto;
    padding: .5rem;
  }
  > div:focus, div:hover {
    color: inherit;
  }
  > div:not(focus) {
    color: var(--foreground-light);
  }
}
  .Editor:hover, .Editor:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

.EditorColors {
  > button {
    background-color: inherit;
    border: none;
    border-radius: 0.25rem;
    margin: .25rem 0;
    padding: .25rem .5rem;
  }
  > button:enabled {
    color: var(--foreground-dark);
    opacity: .7;
    > span:first-child {
      filter: drop-shadow(1px 1px 1px var(--border-dark));
    }
  }
  > button:enabled:hover {
    background-color: var(--background-medium);
    cursor: pointer;
    opacity: 1;
  }
  > button:disabled {
    color: var(--foreground-light);
    opacity: .7;
    > span:first-child {
      filter: drop-shadow(1px 1px 1px var(--border-dark));
    }
  }
}
  .EditorColorsMenu {
    background-color: inherit;
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: .5rem 0;
    position: absolute;
    > table {
      border-spacing: .20rem;
      > tbody {
        > tr {
          > td {
            border: inset 1px var(--border-light);
            cursor: pointer;
            height: .70rem;
            width: 1rem;
          }
          > td:hover {
            border: outset 1px var(--border-light);
            box-shadow: 1px 1px 3px;
          }
        }
      }
    }
  }

.EditorInput {
  > button {
    background-color: inherit;
    border: none;
    border-radius: 0.25rem;
    margin: .25rem 0;
    padding: .25rem .5rem;
  }
  > button:enabled {
    color: var(--foreground-medium);
  }
  > button:enabled:hover {
    background-color: var(--background-medium);
    color: var(--foreground-dark);
    cursor: pointer;
  }
  > button:disabled {
    color: var(--foreground-light);
  }
}
  .EditorInputMenu {
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    padding: .5rem;
    position: absolute;
    width: fit-content;
    z-index: 10;
    > input {
      border: none;
      color: inherit;
      outline: none;
    }
  }
  .EditorInputMenu:hover, .EditorInputMenu:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    > input {
      border-bottom: dotted 2px var(--border-input-active);
      color: var(--foreground-dark);
    }
  }
  .EditorInputMenu:not(focus) {
    > input {
      border-bottom: dotted 2px var(--border-input-inactive);
      color: var(--foreground-light);
    }
  }

.EditorSelect {
  > button {
    background-color: inherit;
    border: none;
    border-radius: 0.25rem;
    margin: .25rem 0;
  }
  > button:enabled {
    color: var(--foreground-medium);
  }
  > button:enabled:hover {
    background-color: var(--background-medium);
    color: var(--foreground-dark);
    cursor: pointer;
  }
  > button:disabled {
    color: var(--foreground-light);
  }
  > button:first-of-type {
    padding: .25rem .1rem .25rem .5rem;
  }
  > button:last-of-type {
    padding: .25rem .5rem .25rem .1rem;
  }
}
  .EditorSelectMenu {
    background-color: var(--background-light);
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: var(--foreground-light);
    padding: .25rem 0;
    position: absolute;
    z-index: 10;
    > div {
      cursor: pointer;
      padding: .5rem;
    }
    > div:hover {
      background-color: var(--background-medium);
      color: var(--foreground-medium);
    }
  }

.EditorToolbar {
  background-color: var(--background-light);
  border-bottom: solid 1px var(--border-light);
  display: flex;
  justify-content: space-between;
  padding: 0 .25rem;
  > div {
    > button {
      background-color: inherit;
      border: none;
      border-radius: 0.25rem;
      margin: .25rem 0;
      padding: .25rem .5rem;
    }
    > button:enabled {
      color: var(--foreground-medium);
    }
    > button:enabled:hover {
      background-color: var(--background-medium);
      color: var(--foreground-dark);
      cursor: pointer;
    }
    > button:disabled {
      color: var(--foreground-light);
    }
  }
}

.News {
  > button {
    background-color: inherit;
    border: none;
    border-radius: 1rem;
    box-shadow: 3px 3px 4px var(--border-light);
    color: var(--foreground-button-close-inactive);
    cursor: pointer;
    float: right;
    height: 2rem;
    margin: .25rem .25rem 0 0;
    width: 2rem;
  }
  > button:hover {
    background-color: var(--background-light);
    box-shadow: 3px 3px 4px var(--border-medium);
    color: var(--foreground-button-close-active);
  }
  > div {
    > div:first-child, > div:last-child {
        min-height: 1rem;
        text-align:center;
      > button {
        background-color: inherit;
        border: solid 1px var(--border-medium);
        border-radius: 0.25rem;
        color: var(--foreground-button-inactive);
        cursor: pointer;
        padding: 0.75rem 1.5rem;
      }
      > button:hover {
        background-color: var(--background-dark);
        box-shadow: 3px 3px 4px var(--border-medium);
        color: var(--foreground-button-active);
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .News-md {
    > div {
      padding: 0 1rem;
    }
  }
}

.NewsList {
  min-height: 12rem;
  > a {
    color: var(--foreground-medium);
    text-decoration: none;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      padding-bottom: .5rem;
      text-align: justify;
      text-justify: inter-word;
    }
    > div:last-child {
      text-align: justify;
      text-justify: inter-word;
    }
  }
  > a:hover {
    color: var(--foreground-dark);
  }
  > div {
    display: flex;
    padding-top: 1rem;
    justify-content: space-between;
    > a {
      color: var(--foreground-medium);
      text-decoration: none;
    }
    a:hover {
      color: var(--foreground-dark);
    }
    > div:nth-child(3) {
      display: flex;
      gap: 1rem;
      > a {
        background-color: inherit;
        border: none;
        color: var(--foreground-medium);
        text-decoration: none;
      }
      a:hover {
        color: var(--foreground-dark);
      }
    }
    > div:last-child {
      display: flex;
      gap: .75rem;
      > a {
        color: var(--foreground-medium);
        text-decoration: none;
      }
      a:hover {
        color: var(--foreground-dark);
      }
    }
  }
}
.NewsList:not(:last-child) {
  border-bottom: solid 1px var(--border-medium);
}
@media only screen and (max-width: 600px) {
  .NewsList {
    padding: 1rem;
    > a {
      > div:first-child {
        align-items: center;
      }
    }
    > div {
      flex-wrap: wrap;
      gap: .25rem;
      > a:first-child {
        flex-basis: 100%;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .NewsList {
    padding: 1.25rem;
  }
}

.NewsListFlag {
  color: red;
}
.NewsListStar {
  color: orange;
}
.NewsListThumbsDown {
  color: red;
}
.NewsListThumbsUp {
  color: green;
}

.NewsView {
  > div:first-child {
    display: flex;
    justify-content: center;
    padding: 1.25rem 0 .25rem 0;
    > span {
      display: flex;
    }
  }
  > div:nth-child(3) {
    display: flex;
    justify-content: space-between;
    padding: .25rem 0 1.25rem 0;
  }
  > div:nth-child(4) {
    padding: 2rem 0;
    text-align: justify;
  }
  > div:nth-child(5) {
    padding: 1.5rem 2rem;
    > div {
      background-color: whitesmoke;
      border: solid 1px var(--border-light);
      border-radius: 5rem;
      display: flex;
      gap: .3rem;
      justify-content: center;
      padding: 0 1rem .5rem 1rem;
      > div {
        > div:last-child {
          border: solid 1px var(--border-light);
          border-radius: 0.25rem;
          padding: .6rem 1rem;
        }
        > div:last-child:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .NewsView {
    padding: 1rem;
    > div:nth-child(5) {
      padding: 1.5rem 0;
      > div {
        flex-wrap: wrap;
        padding: 1rem 0 .5rem 0;
        > div:first-child {
          > div:nth-child(2) {
            > input {
            width: 15rem;
            }
          }
          > div:nth-child(3) {
            width: 17.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .NewsView {
    padding: 0 2rem;
  }
}

.NewsViewFlag {
  color: red;
}

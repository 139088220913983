.Error {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-left: 1rem;
    > :not(:last-child) {
      color: var(--foreground-notify-error);
    }
  }
}

.Feedback {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
@media only screen and (max-width: 600px) {
  .Feedback {
    > div {
      > div {
        > textarea {
          width: 20rem;
        }
      }
    }
  }
}

.Profile {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.Recover {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.Report {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  > div:nth-child(4) {
    align-items: center;
    display: flex;
    flex-direction: column;
    > span {
      padding: .25rem 0;
    }
    > div {
      display: flex;
      gap: 0.25rem;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div:last-child {
          align-items: flex-end;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Report {
    > div:nth-child(2) {
      > div {
        > input {
          width: 17rem;
        }
      }
    }
    > div:nth-child(4) {
      > div {
        align-items: center;
        flex-direction: column-reverse;
      }
    }
    > div:nth-child(5) {
      > div {
        > textarea {
          width: 19rem;
        }
      }
    }
  }
}

.Settings {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.Signin {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  > div:nth-child(4) {
    > a:first-child {
      border-right: solid 1px var(--border-medium);
      padding: 0 .5rem 0 0;
    }
    > a:last-child {
      padding: 0 0 0 .5rem;
    }
  }
}

.Signup {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

body {
  background: var(--background);
  color: var(--foreground-dark);
}

.AButton {
  color: var(--foreground-link-inactive);
  text-decoration: none;
}
  .AButton:hover {
    color: var(--foreground-link-active);
  }

.Footer {
  align-items: center;
  background-color: var(--background-dark);
  border-top: solid 1px var(--border-footer);
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: static;
  width: 100%;
  > div {
    align-items: center;
    display: flex;
    height: 10rem;
    > a {
      color: var(--foreground-link-footer-inactive);
      text-decoration: none;
    }
    > a:hover {
      color: var(--foreground-link-footer-active);
    }
  }
  > span:last-child {
    color: var(--foreground-medium);
  }
}
@media only screen and (max-width: 600px) {
  .Footer-sm {
    > div {
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      width: 100%
    }
  }
}
@media only screen and (min-width: 600px) {
  .Footer-md {
    > div {
      justify-content: space-evenly;
      width: 100%
    }
  }
}

.Left {
  height: 100%;
  width: 20rem;
}

.Loading {
  background-color: var(--background-loading-inactive);
  max-height: .25rem;
  position: fixed;
  width: 100%;
  div {
    background-color: var(--background-loading-active);
    height: .25rem;
    width: 100%;
  }
}

.Main {
  height: 100vh;
  > div {
    display: flex;
    min-height: calc(100% - 16rem);
    width: 100%;
    > div {
      border-left: solid 1px var(--border-light);
      border-right: solid 1px var(--border-light);
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .Main-md {
    > div {
      flex-direction: column;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .Left-xl {
    width: calc((100% - 45rem) / 2);
  }
  .Main-xl {
    > div {
      > div {
        max-width: 45rem;
      }
    }
  }
  .Right-xl {
    max-width: calc((100% - 45rem) / 2);
    width: calc((100% - 45rem) / 2);
  }
}

.Navbar {
  border-bottom: solid 1px var(--border-medium);
  display: flex;
  padding: .5rem 1rem;
}
@media only screen and (max-width: 600px) {
  .Navbar-sm {
    flex-direction: column;
    gap: .5rem;
    > div {
      align-self: center;
    }
    > nav {
      align-self: end;
    }
  }
}
@media only screen and (min-width: 600px) {
  .Navbar-md {
    align-items: center;
    justify-content: space-between;
  }
}

.Notify {
  background-color: var(--background-notify);
  border-radius: .25rem;
  display: flex;
  height: 3.5rem;
  opacity: 0.75;
  overflow: hidden;
  padding: 0 .25rem 0 1rem;
  position: fixed;
  right: 1rem;
  > svg {
    align-self: center;
  }
  > span {
    align-self: center;
    padding: 0 1rem;
    width: 20rem;
  }
  > button {
    align-self: start;
    background-color: inherit;
    border: none;
    color: var(--foreground-button-close-inactive);
    cursor: pointer;
    margin-top: .25rem;
  }
  > button:hover {
    color: var(--foreground-button-close-active);
  }
}
  .NotifyErr {
    color: var(--foreground-notify-error);
  }
  .NotifyInfo {
    color: var(--foreground-notify-info);
  }
  .NotifyOk {
    color: var(--foreground-notify-ok);
  }
  .NotifyWarn {
    color: var(--foreground-notify-warn);
  }

.ProfileMenu {
  div {
    background-color: var(--background-light);
    border: solid 1px var(--border-light);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: .25rem;
    padding: .5rem 0;
    position: absolute;
    right: 1rem;
    width: 13rem;
    z-index: 10;
    a {
      display: block;
      color: var(--foreground-light);
      padding: .5rem 1rem .5rem .25rem;
      text-decoration: none;
      > svg {
        width: 2.5rem;
      }
      > span {
        border-left: solid 1px var(--border-light);
        padding-left: .5rem;
      }
    }
    a:hover {
      color: var(--foreground-medium);
      background-color: var(--background-medium);
    }
  }
}

.Right {
  height: 100%;
  width: 20rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink {
  50% { opacity: 0; }
}
.animation-blink {
  animation: 1s blink step-end infinite;
}

@keyframes loading {
  0% { transform: translateX(0) scaleX(0); }
  40% { transform: translateX(0) scaleX(0.4); }
  100% { transform: translateX(100%) scaleX(0.5); }
}
.animation-loading {
  animation: loading 1s infinite linear;
  transform-origin: 0% 50%;
}

